import React from "react"

const test = `
<div id="SOIDIV_new-horizon-co"
  data-so-page="new-horizon-co"
  data-height="700"
  data-style="border: 1px solid #d8d8d8; min-width: 290px; max-width: 900px;"
  data-psz="00"
></div>
<script type="text/javascript" src="https://cdn.oncehub.com/mergedjs/so.js"></script>
`

const ScheduleMeeting = () => {
  return (
    <div>
      <iframe title="OnceHub" height="750" width="100%" srcDoc={test}></iframe>
    </div>
  )
}

export default ScheduleMeeting
